import { Controller } from "stimulus"
import Slider from "packs/rSlider.js"

export default class extends Controller {
  static targets = [ "range_start", "range_end", "deadline_months", "deadline_date" ]

  connect() {
    this.initBudgetSlider()
    this.initDeadlineSlider()
    // this.range_startTarget.
  }

  initBudgetSlider() {
    var budgetRangeSlider = new rSlider({
        target: '#quote_range',
        values: [500, 1000, 3000, 5000, 10000],
        range: true,
        tooltip: false,
        labels: true,
        set: [1000, 3000],
        onChange: function (vals) {
          $("#quote_range_start").val(vals.split(',')[0])
          $("#quote_range_end").val(vals.split(',')[1])
        }
    });
  }
  initDeadlineSlider() {
    var that = this
    // var dt = new Date(selectedDates).addHours(1).setMinutes(0);
    //               deadline_date.setDate(dt);
    var deadlineRangeSlider = new rSlider({
        target: '#quote_deadline_months',
        values: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
        range: false,
        tooltip: false,
        scale: true,
        labels: true,
        set: ["3"],
        onChange: function (vals) {
            var deadline_date = document.querySelector("#quote_deadline_date")._flatpickr;
            var dt = new Date()
            deadline_date.set('minDate', dt);
            deadline_date.setDate(that.addMonths(new Date(dt),vals));
        }
    });
  }

  addMonths(date, months) {
      var d = date.getDate();
      date.setMonth(date.getMonth() + +months);
      if (date.getDate() != d) {
        date.setDate(0);
      }
      return date;
  }

}
